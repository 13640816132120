import React, { useContext } from "react";

import OutlinePlayIcon from "./OutlinePlayIcon.jsx";
import "../assets/styles/components/Laptop.scss";
import laptopPicture from "../assets/static/pictures/laptop.png";
import { VideoContext } from "../content/videoContent";
import { LanguageContext } from "../content/textContent";

export default function Laptop() {
  const { setVideoState } = useContext(VideoContext);

  const {
    laptop: {
      title: { main, blue },
      body: { paragraph1, paragraph2 },
      alt,
      video,
    },
  } = useContext(LanguageContext);

  function handleClick() {
    setVideoState({
      videoId: video,
      videoState: true,
    });
  }

  return (
    <section id="laptop" className="laptop">
      <div className="container">
        <h1 className="laptop__title">
          {main} <span className="blue">{blue}</span>
        </h1>
        <p className="laptop__text">{paragraph1}</p>
        <p className="laptop__text">{paragraph2}</p>
        <div className="laptop__image-container">
          <OutlinePlayIcon
            color="#007db8"
            handleClick={handleClick}
            width="80"
          />
          <img className="laptop__image" src={laptopPicture} alt={alt} />
        </div>
      </div>
    </section>
  );
}
