import React, { useContext } from "react";

import TestimonialsCard from "./TestimonialsCard.jsx";
import "../assets/styles/components/Testimonials.scss";
import face1 from "../assets/static/pictures/testimonial-1-ita.jpg";
import face2 from "../assets/static/pictures/testimonial-2-ita.jpg";
import face3 from "../assets/static/pictures/testimonial-3-ita.jpg";

import { LanguageContext } from "../content/textContent";

export default function Testimonials(props) {
  const pictures = [face1, face2, face3];
  const {
    testimonials: {
      title: { mainPart, bluePart },
      cards
    }
  } = useContext(LanguageContext);

  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <h1 className="testimonials__title">
          {754} {mainPart} <span className="blue">{bluePart}</span>
        </h1>
        <div className="testimonials__card-container">
          {cards.map(({ name, text, alt, video }, i) => {
            return (
              <TestimonialsCard
                text={text}
                name={name}
                src={pictures[i]}
                alt={alt}
                video={video}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
