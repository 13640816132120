import React, { useContext } from "react";

//import Button from "./Button.jsx";
import VimeoPlayer from "./VimeoPlayer.jsx";

import "../assets/styles/components/DoneForYou.scss";
import circle from "../assets/static/icons/circle.svg";
import rectangle from "../assets/static/icons/rectangle.svg";

import { LanguageContext } from "../content/textContent";
import { VideoContext } from "../content/videoContent.js";

export default function DoneForYou(props) {
  const {
    doneForYou: { title, paragraph, /*button,*/ blue }
  } = useContext(LanguageContext);

  const { doneForYou } = useContext(VideoContext);

  return (
    <section id="done-for-you" className="done-for-you">
      <img className="done-for-you__circle" src={circle} alt="circle" />

      <div className="container">
        <h1 className="done-for-you__title">
          {title} <span className="blue">{blue}</span>
        </h1>
        <VimeoPlayer videoId={doneForYou} />
        <p className="done-for-you__text">{paragraph}</p>
        {/*
          <Button
          text={button}
          backgroundColor="#6066F2"
          width="180px"
          hoverColor="#f0f0f0"
        />
        */}
      </div>
      <img className="done-for-you__rect" src={rectangle} alt="rectangle" />
    </section>
  );
}
