import { createContext } from "react";

export const englishContent = {
  navigation: {
    tools: {
      id: "tools",
      name: "Tools",
    },
    doneForYou: {
      id: "done-for-you",
      name: "Done For You",
    },
    testimonials: {
      id: "testimonials",
      name: "Testimonials",
    },
    pricing: {
      id: "pricing",
      name: "Pricing",
    },
  },
  top: {
    mainTitle: "Sales Jet: 360 Degree Marketing",
    strings: ["Done by You!", "Done for You!"],
    buttonText: "Let's Go",
  },
  laptop: {
    title: {
      main: "Software as a Service taken to the next level, made specifically",
      blue: "for the common enterpreneur",
    },
    body: {
      paragraph1:
        'We all know a computer will never be able to really give a full service by itself. The "software as a service" experience thus is not really possible without some human interaction.',
      paragraph2:
        "This is where Sales Jet comes in by unifying the satisfying experience of an incredible fully-featured marketing software with the pleasure of being followed by an expert marketing and media agency.",
    },
    alt: "Sales Jet in action.",
    video: 424152297,
  },
  tools: {
    title: {
      main1: "A full range of",
      blue: "tools",
      main2: "at your disposal.",
    },
    body: {
      paragraph1:
        'Let\'s face it, engaging in marketing activity <span class="dark-blue">is a nightmare</span>. The average marketer spends his whole day attempting to join & sync several platforms that aren’t compatible with one another other. Sales Jet to the rescue! <span class="dark-blue">A platform made for the common enterpreneur.</span>',
      paragraph2:
        "Save on your tools by combining a fully-fledged drag-and-drop funnel builder, a call-center software able to <span class=\"dark-blue\">combine phone calls with form submittions</span>, a complete email platform and so much more, all in one software. Even more important, Sales Jet is EASY and intuitive to operate. Once you'll try it, you'll never come back to your old platform again. Good morning, enterpreneur, the nightmare is over!",
    },
    button: "Find out more",
  },
  doneForYou: {
    title: "Done for",
    blue: "you",
    paragraph:
      "We could not make a platform designed for the modern-day entrepreneur without giving the option of choosing a done-for-you service. From a small funnel page to creating an incredible influencer campaign, Sales Jet has your back. Simply ask our friendly support for help, our marketing veterans in tens of different industries will be glad to arrange a constructive partnership for both parties.",
    button: "Find Out More",
  },
  testimonials: {
    title: {
      mainPart: "satisfied",
      bluePart: "customers",
    },
    cards: [
      {
        name: "Lucrezia Lobuono",
        text:
          "Since I use Sales Jet my marketing has improved! I can do emails, text messages, create landing pages and even make my own video courses! I reccomend it to any enterpreneur!",
        video: 402295145,
        alt: "Lucrezia Lobuono",
      },
    ],
  },
  pricing: {
    btnText: "Sign Up!",
    title: {
      main: "Monthly",
      blue: "pricing",
    },
    card1: {
      title: "Free",
      price: "0$",
      subtitle: "14 days Free trial",
      features: ["250 leads", "30 pages", "1GB storage limit"],
    },
    card2: {
      title: "First Class",
      price: "67$",
      subtitle: "Our basic plan",
      features: [
        "Everything in the free plan",
        "Up to 5.000 leads",
        "100 pages",
        "5GB storage limit",
        "Custom Domains",
        "Free SSL",
        "Additional administrators",
      ],
    },
    card3: {
      title: "Private Class",
      price: "197$",
      subtitle: "Top Choice",
      features: [
        "same as First Class",
        "20.000 leads",
        "500 pages",
        "35GB storage limit",
        "Multiple Additional Administrators",
        "Done for you support 🎉",
        "Marketing experts offer review!",
      ],
    },
  },
  privateOffer: {
    title: {
      main: "Your offer?",
      blue: "Our analysis",
    },
    paragraph:
      "Private Jet users will have a complimentary analysis of their current marketing campaign and an in-depth study of the offer and monetization system of your business. We will come up with an incredible offer that SELLS in the actual market. Join the exclusive Private Jet users' group now and enjoy all the incredible benefits.",
    button: "Subscribe now!",
  },
  amazon: {
    title: {
      main: "The cherry on top?",
      blue: "Our stellar Amazon integration!",
    },
    body: {
      paragraph1:
        "Amazon is the present and future for selling, an ever-evolving platform that has been conquering the world's economy at a surprising rate. However, many entrepreneurs are not capitalizing on this tool by moving their products onto the modern and super-efficient Amazon ecosystem. With its incredible tools, Sales Jet can help even in this endeavor.",
      paragraph2:
        "Exclusive to Private Jet members, our Amazon integration is just what you need to move your business to the next level, with the same ease of use our system is known for.",
    },
    button: "Find Out More!",
  },
  footer: {
    copyright: "AdyProduction LLC. All rights reserved.",
  },
};

export const italianContent = {
  navigation: {
    tools: {
      id: "tools",
      name: "Strumenti",
    },
    doneForYou: {
      id: "done-for-you",
      name: "Fatto per Te",
    },
    testimonials: {
      id: "testimonials",
      name: "Testimonials",
    },
    pricing: {
      id: "pricing",
      name: "Prezzi",
    },
  },
  top: {
    mainTitle: "Sales Jet: Marketing a 360 Gradi",
    strings: ["Fatto da Te!", "Fatto per Te!"],
    buttonText: "Iniziamo!",
  },
  laptop: {
    title: {
      main: "Il Software come Servizio portato al prossimo livello, fatto",
      blue: "per il normale imprenditore",
    },
    body: {
      paragraph1:
        'Sappiamo tutti che un computer non potrà mai dare un servizio completo in sé. Il "software come servizio" non è dunque possibile senza un\'interazione umana.',
      paragraph2:
        "Qui si inserisce Sales Jet, unificando l'esperienza soddisfacente di un software di marketing incredibilmente completo con il piacere di essere seguiti da un'esperta agenzia di media e marketing.",
    },
    alt: "Sales Jet in azione.",
    video: 424152266,
  },
  tools: {
    title: {
      main1: "Un'intera batteria di",
      blue: "strumenti",
      main2: "a tua disposizione.",
    },
    body: {
      paragraph1:
        "Guardiamo in faccia la realtà. Occuparsi di attività di marketing può rapidamente trasformarsi in <span class=\"dark-blue\">un incubo</span>. Il marketer medio passa le sue giornate cercando di far funzionare insieme un'insieme di strumenti che non sono stati creati per interagire l'uno con l'altro. Ma ecco Sales Jet che arriva in tuo soccorso. <span class=\"dark-blue\">Una piattaforma creata per l'imprenditore normale.</span>",
      paragraph2:
        "Risparmia sugli strumenti combinando un completo funnel builder drag-and-drop, un software per call-center in grado di <span class=\"dark-blue\">collegare le chiamate con l'invio di moduli</span>, una completa piattaforma per l'invio di email e molto di più, il tutto in un unico software. E, ancora più importante, Sales Jet è FACILE ed intuitivo nell'uso. Una volta che l'avrai provato, non tornerai mai più all'incubo del passato. Buongiorno, imprenditore!",
    },
    button: "Scopri di più",
  },
  doneForYou: {
    title: "Fatto per",
    blue: "te",
    paragraph:
      "Non avremmo potuto creare una piattaforma per l'imprenditore senza dare la possibilità di scegliere un servizio completo fatto-per-te. Sia che tu abbia bisogno di una piccola pagina per il tuo funnel o di creare un'intera campagna di influencer marketing per promuovere il tuo business, Sales Jet è la scelta giusta per te. Inoltra semplicemente una richiesta al nostro amichevole supporto, i nostri veterani del marketing con esperienza in decine di mercati diversi organizzeranno con piacere una partnership costruttiva per entrambi.",
    button: "Scopri di più",
  },
  testimonials: {
    title: {
      mainPart: "Clienti",
      bluePart: "soddisfatti",
    },
    cards: [
      {
        name: "Lucrezia Lobuono",
        text:
          "Da quando utilizzo Sales Jet ho ottenuto grandi risultati sul marketing. Puoi creare i tuoi messaggi e mandare le comunicazioni attraverso moltissimi canali e puoi addirittura creare i tuoi videocorsi!",
        video: 402295181,
        alt: "Lucrezia Lobuono",
      },
      {
        name: "David Campana",
        text:
          "Da pochi mesi uso Sales Jet, è intuibile e molto semplice! In poco tempo ho costruito il mio funnel, poi un altro e poi un altro ancora! Il mio database di prospetti continua a crescere!",
        video: 397755391,
        alt: "David Campana.",
      },
      {
        name: "Nicola Candian",
        text:
          "Sono Private Jet da un anno. In dieci minuti crei la tua landing page. L'automazione è semplicissima, puoi fare tantissime cose tra cui la creazione di video corsi. Il supporto è stellare!",
        video: 397755373,
        alt: "Nicola Candian",
      },
    ],
  },
  pricing: {
    btnText: "Iscriviti!",
    title: {
      main: "Prezzo",
      blue: "mensile",
    },
    card1: {
      title: "Gratis",
      price: "0$",
      subtitle: "Prova gratuita di 14 giorni",
      features: ["250 contatti", "30 pagine", "Limite di archiviazione di 1GB"],
    },
    card2: {
      title: "First Class",
      price: "67$",
      subtitle: "Il nostro piano base",
      features: [
        "Tutte le caratteristiche del piano base",
        "Fino a 5000 contatti",
        "100 pagine",
        "Limite di archiviazione di 5GB",
        "Domini personalizzati",
        "Certificato SSL gratuito",
        "Amministratori aggiuntivi",
      ],
    },
    card3: {
      title: "Private Jet",
      price: "197$",
      subtitle: "La scelta top!",
      features: [
        "Gli stessi della First Class",
        "20.000 contatti",
        "500 pagine",
        "Limite di archiviazione di 35GB",
        "Amministratori aggiuntivi multipli",
        "Supporto fatto per te 🎉",
        "Revisione della tua offerta da parte degli esperti!",
      ],
    },
  },
  privateOffer: {
    title: {
      main: "La tua offerta?",
      blue: "La nostra analisi",
    },
    paragraph:
      "Gli utenti Private Jet avranno a disposizione un'analisi gratuita della loro campagna di marketing attuale ed uno studio approfondito della loro offerta e sistema di monetizzazione del loro business. Creeremo un'offerta incredibile che semplicemente <span class=\"dark-blue bold\">VENDA</span> nel mercato attuale. Unisciti subito al gruppo esclusivo di utenti Private Jet e approfitta degli incredibili benefici che abbiamo scelto, solo per te.",
    button: "Iscriviti subito!",
  },
  amazon: {
    title: {
      main: "La ciliegina sulla torta?",
      blue: "La nostra fantastica integrazione Amazon!",
    },
    body: {
      paragraph1:
        "Amazon rappresenta il presente ed il futuro del commercio, una piattaforma sempre in evoluzione che ha saputo conquistare l'economia mondiale ad una velocità sorprendente. Stranamente molti imprenditori non hanno mai preso in considerazione l'inserimento dei propri prodotti nell'ecosistema di Amazon altamente efficiente. Con i suoi strumenti incredibili, Sales Jet può aiutarti anche in questo compito così delicato.",
      paragraph2:
        "La nostra integrazione Amazon, esclusiva per gli utenti Sales Jet, è proprio ciò di cui hai bisogno per far arrivare il tuo business al prossimo livello, con la facilità d'uso che caratterizza la nostra piattaforma.",
    },
    button: "Scopri di più!",
  },
  footer: {
    copyright: "AdyProduction LLC. Tutti i diritti riservati.",
  },
};

export const LanguageContext = createContext(italianContent);
