import React, { useContext } from "react";

import "../assets/styles/components/VideoPlayer.scss";
import backIcon from "../assets/static/icons/chevron-back-circle-outline.svg";
import { VideoContext } from "../content/videoContent";

export default function VideoPlayer() {
  const {
    videoState: { videoId },
    setVideoState
  } = useContext(VideoContext);

  //console.log(useContext(VideoContext));
  function handleClick(e) {
    setVideoState({ videoId: "", state: false });
  }

  return (
    <div className="VideoPlayer">
      <img
        onClick={handleClick}
        className="VideoPlayer__back-icon"
        src={backIcon}
        alt="Go Back"
      />
      <iframe
        title="top-intro-video"
        className="VideoPlayer__iframe"
        //src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
        src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  );
}
