import React, { useContext } from "react";

import { LanguageContext } from "../content/textContent";
import "../assets/styles/components/Footer.scss";
import footerImage from "../assets/static/pictures/footerImage.png";

export default function Footer() {
  const {
    footer: { copyright }
  } = useContext(LanguageContext);
  return (
    <footer className="footer container">
      <span className="footer__copyright">
        &copy; {new Date().getFullYear()} {copyright}{" "}
      </span>
      <img
        className="footer__image"
        src={footerImage}
        alt="Face of Andrea d'Agostini"
      />
    </footer>
  );
}
