import React, { useContext, useState } from "react";
import { Link } from "react-router-dom"
import { LanguageContext } from "../content/textContent";

import "../assets/styles/components/LanguageSelector.scss";
import italianFlag from "../assets/static/pictures/italian.png";
import englishFlag from "../assets/static/pictures/english.png";

export default function LanguageSelector() {
  const { setLanguage } = useContext(LanguageContext);
  const [clicked, setClicked] = useState(false);

  const handleClick = language => {
    setLanguage(language);
    setClicked(false);
  };

  const handleBtnClick = () => {
    setClicked(true);
  };

  const flagsHtml = (
    <div className="language-selector">
      <div className="language-selector__flag-div">
        <Link className="language-selector__link" to="/it">
          <img
            className="language-selector__image"
            src={italianFlag}
            alt="Leggi il sito in italiano"
            onClick={() => handleClick("italian")}
          />
        </Link>
      </div>
      <div className="language-selector__flag-div">
        <Link className="language-selector__link" to="/en">
          <img
            className="language-selector__image"
            src={englishFlag}
            alt="Read the website in English"
            onClick={() => handleClick("english")}
          />
        </Link>
      </div>
    </div>
  );

  const button = (
    <button
      className="langugage-selector__start-button"
      onClick={handleBtnClick}
    >
      Not your Language?
    </button>
  );

  return clicked ? flagsHtml : button;
}
