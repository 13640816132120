import { createContext } from "react";

export const italianVideos = {
  top: 387785626,
  tools: 387787202,
  offer: 387786996,
  doneForYou: 387786447,
};

export const englishVideos = {
  top: 388292440,
  tools: 388292750,
  offer: 388293159,
  doneForYou: 388292928,
};

export const VideoContext = createContext(italianVideos);
