import React, { useContext } from "react";

import "../assets/styles/components/TestimonialsCard.scss";
import playIcon from "../assets/static/icons/play-circle-filled.svg";
import { VideoContext } from "../content/videoContent";

export default function Card(props) {
  const { text, src, alt, name, video } = props;

  const { setVideoState } = useContext(VideoContext);

  function handleClick() {
    setVideoState({
      videoId: video,
      videoState: true
    });
  }

  return (
    <div className="card">
      <div className="card__image-container">
        <img className="card__img" src={src} alt={alt} />
        <img
          className="card__play"
          src={playIcon}
          alt="Play"
          onClick={handleClick}
        />
      </div>

      <p className="card__name">{name}</p>
      <p className="card__description">{text}</p>
    </div>
  );
}
