import "core-js";
import "raf/polyfill";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom"

import ReactDOM from "react-dom";

import App from "./App.jsx";
import "./assets/styles/index.scss";

ReactDOM.render(<Router><App /></Router>, document.getElementById("root"));


