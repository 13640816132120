import React from "react";

import "../assets/styles/components/Button.scss";

export default function Button(props) {
  const { text, backgroundColor, width, hoverColor, clickHandler } = props;

  function handleMouseEnter(e) {
    e.target.style.color = hoverColor;
  }

  function handleMouseLeave(e) {
    e.target.style.color = "";
  }

  return (
    <button
      className="button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor, width }}
      onClick={clickHandler && clickHandler}
    >
      {text}
    </button>
  );
}
