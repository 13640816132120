import React, { useContext } from "react";
//import { sanitize } from "dompurify";

import Button from "./Button.jsx";
import VimeoPlayer from "./VimeoPlayer.jsx";

import { scrollTo } from "../helpers/helpers";
import "../assets/styles/components/PrivateOffer.scss";

import { LanguageContext } from "../content/textContent";
import { VideoContext } from "../content/videoContent";

export default function PrivateOffer(props) {
  const {
    privateOffer: {
      title: { main, blue },
      paragraph,
      button,
    },
  } = useContext(LanguageContext);

  const { offer } = useContext(VideoContext);

  const handleClick = () => {
    scrollTo("pricing");
  };

  return (
    <section id="private-offer" className="private-offer">
      <div className="container">
        <div className="private-offer__left-container">
          <h1 className="private-offer__title">
            {main} <span className="blue">{blue}</span>
          </h1>
          <VimeoPlayer
            videoId={offer}
            laptopUpDNone={true}
            hideOnDesktop={true}
          />
          <p
            className="private-offer__text"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
          <Button
            text={button}
            backgroundColor="#6066F2"
            width="max-content"
            clickHandler={handleClick}
          />
        </div>
        <VimeoPlayer videoId={offer} hideOnLaptop={true} />
      </div>
    </section>
  );
}
