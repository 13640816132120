import React, { useContext } from "react";

import "../assets/styles/components/Top.scss";

import VimeoPlayer from "./VimeoPlayer.jsx";
import Button from "./Button.jsx";
import MainTitle from "./MainTitle.jsx";

import { scrollTo } from "../helpers/helpers";

import { LanguageContext } from "../content/textContent";
import { VideoContext } from "../content/videoContent";

export default function Top() {
  const {
    top: { buttonText }
  } = useContext(LanguageContext);

  const { top } = useContext(VideoContext);

  const handleClick = () => {
    scrollTo("pricing");
  };

  return (
    <section id="top" className="top">
      <div className="container">
        <div className="top__container">
          <div className="top__left-container">
            <MainTitle />
            <VimeoPlayer videoId={top} hideOnDesktop={true} />
            <Button
              text={buttonText}
              hoverColor="grey"
              clickHandler={handleClick}
              width={"max-content"}
            />
          </div>
          <VimeoPlayer videoId={top} hideOnLaptop={true} />
        </div>
      </div>
    </section>
  );
}
