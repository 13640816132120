import React, { useEffect, useRef, useContext } from "react";

import { LanguageContext } from "../content/textContent";
import Typed from "typed.js";

export default function MainTitle() {
  const {
    top: { mainTitle, strings }
  } = useContext(LanguageContext);
  const span = useRef(null);

  useEffect(() => {
    const options = {
      strings: strings,
      typeSpeed: 150,
      startDelay: 500,
      backSpeed: 50,
      backDelay: 1000,
      loop: true,
      loopCount: 3,
      showCursor: true,
      cursorChar: "|"
    };

    const typed = new Typed(span.current, options);
    typed.start();

    return () => {
      typed.destroy();
    };
  }, [strings]);

  return (
    <h1 className="top__title">
      {mainTitle} <span ref={span}></span>
    </h1>
  );
}
