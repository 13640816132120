import React, { useState, useEffect, useContext } from "react";
import Navigation from "./Navigation.jsx";
import Top from "./Top.jsx";
import Laptop from "./Laptop.jsx";
import Tools from "./Tools.jsx";
import DoneForYou from "./DoneForYou.jsx";
import Testimonials from "./Testimonials.jsx";
import Pricing from "./Pricing.jsx";
import PrivateOffer from "./PrivateOffer.jsx";
// import Amazon from "./Amazon.jsx";
import Footer from "./Footer.jsx";
import VideoPlayer from "./VideoPlayer.jsx";

import {
  italianVideos,
  englishVideos,
  VideoContext
} from "../content/videoContent";
import { LanguageContext } from "../content/textContent.js";

export default function HomePage({language}) {
  const {
    testimonials: { cards }
  } = useContext(LanguageContext);

  const [videoState, setVideoState] = useState({
    videoId: "",
    videoState: false
  });

  const { Provider: VideoProvider } = VideoContext;
  const { videoState: isVideoOpen } = videoState;

  useEffect(() => {
    window.addEventListener(
      "keydown",
      () => {
        if (isVideoOpen) setVideoState({ videoId: "", videoState: false });
      },
      [videoState]
    );
  });

  return (
    <VideoProvider
      value={{
        ...(language === "italian" ? italianVideos : englishVideos),
        videoState,
        setVideoState
      }}
    >
      {isVideoOpen ? (
        <VideoPlayer></VideoPlayer>
      ) : (
        <>
          <Navigation />
          <Top />
          <Laptop />
          <Tools />
          <DoneForYou />
          {cards.length > 0 && <Testimonials />}
          <Pricing />
          <PrivateOffer />
          {/* <Amazon /> */}
          <Footer />
        </>
      )}
    </VideoProvider>
  );
}
