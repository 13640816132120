import React, { useState } from "react";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import "./assets/styles/App.scss";

import HomePage from "./components/HomePage.jsx";

import {
  englishContent,
  LanguageContext,
  italianContent
} from "./content/textContent";

import { checkLanguage } from './helpers/helpers';

function App() {
  const [language, setLanguage] = useState(checkLanguage(window.location.pathname));

  const { Provider: LanguageProvider } = LanguageContext;

  const location = useLocation()
  const affId = new URLSearchParams(location.search).get('sj_aff')

  console.log(affId)

  return (
    <LanguageProvider
      value={{
        ...(language === "italian" ? italianContent : englishContent),
        language,
        setLanguage
      }}
    >
      <div className="App">
        
        <Switch>
          <Route exact path="/en">
            <HomePage language="english" />
          </Route>
          <Route exact path="/it">
            <HomePage language="italian" />
          </Route>
          <Route exact path="*">
            <Redirect push to={affId ? `/en?sj_aff=${affId}` : '/en' } />
          </Route>
        </Switch>
        
      </div>
    </LanguageProvider>
  );
}

export default App;
