import React, { useContext, useState } from "react";

import "../assets/styles/components/Navigation.scss";
import logo from "../assets/static/pictures/logo.png";
import hamburgerIcon from "../assets/static/icons/hamburger-icon.svg";

import { LanguageContext } from "../content/textContent";

import LanguageSelector from "./LanguageSelector.jsx";

export default function Navigation() {
  const {
    navigation: { tools, doneForYou, testimonials, pricing },
  } = useContext(LanguageContext);

  const [menuActive, setMenuActive] = useState(false);

  function handleClick() {
    setMenuActive(!menuActive);
  }

  const menu = [tools, doneForYou, testimonials, pricing];

  const menuHTML = menu.map((item, i) => {
    const { id, name } = item;
    return (
      <li
        key={i}
        className={`navigation__item${!menuActive && " tab-port-d-none"}`}
      >
        <a href={`#${id}`}>{name}</a>
      </li>
    );
  });

  const mobileMenuHTML = (
    <div className="navigation__mobile-menu--active">
      {menu.map((item, i) => {
        const { id, name } = item;
        return (
          <li
            key={i}
            className={`navigation__item${!menuActive && " tab-port-d-none"}`}
          >
            <a href={`#${id}`} onClick={handleClick}>
              {name}
            </a>
          </li>
        );
      })}
    </div>
  );

  return (
    <div className="navigation">
      <nav className="navigation__nav">
        <ul className="navigation__ul">
          <li className="navigation__logo">
            <a href="/">
              <img src={logo} alt="Sales Jet Logo" />
            </a>
          </li>
          <div
            className="navigation__mobile-menu tabport-up-d-none"
            onClick={handleClick}
          >
            <img
              className="navigation__hamburger"
              src={hamburgerIcon}
              alt="Mobile menu activator"
            />
          </div>
          {menuActive && mobileMenuHTML}
          {!menuActive && menuHTML}
        </ul>
        <LanguageSelector />
      </nav>
    </div>
  );
}
