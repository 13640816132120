import React, { useContext } from "react";
import PricingCard from "./PricingCard.jsx";
import "../assets/styles/components/Pricing.scss";

import { LanguageContext } from "../content/textContent";

export default function Pricing() {
  const {
    pricing: {
      title: { main, blue },
      btnText,
      card1,
      card2,
      card3
    }
  } = useContext(LanguageContext);

  const btnStyles = {
    btnBackgroundColor: "#6066F2",
    btnText: btnText
  };

  return (
    <section id="pricing" className="pricing">
      <div className="container">
        <h2 className="pricing__title">
          {main + " "}
          <span className="blue">{blue}</span>
        </h2>

        <div className="pricing__cards-container">
          <PricingCard {...card1} {...btnStyles} />
          <PricingCard {...card2} {...btnStyles} />
          <PricingCard {...card3} {...btnStyles} />
        </div>
      </div>
    </section>
  );
}
