import React from "react";

import "../assets/styles/components/VimeoPlayer.scss";

export default function VimeoPlayer({
  videoId,
  hideOnLaptop,
  hideOnTabLand,
  hideOnTabPort,
  tabportUpDNone,
  tablandUpDNone,
  hideOnDesktop
}) {
  return (
    <center className="vimeo-player">
      <iframe
        title="top-intro-video"
        className={`vimeo-player__iframe${
          hideOnTabLand ? " tab-land-d-none" : ""
        }${hideOnLaptop ? " laptop-d-none" : ""}${
          tabportUpDNone ? " tabport-up-d-none" : ""
        }${hideOnTabPort ? " tab-port-d-none" : ""}${
          hideOnDesktop ? " desktop-d-none" : ""
        }`}
        src={`https://player.vimeo.com/video/${videoId}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="fullscreen"
        allowFullScreen
      ></iframe>
    </center>
  );
}
