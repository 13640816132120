import React from "react";
import { useLocation } from 'react-router-dom'

import Button from "./Button.jsx";
import "../assets/styles/components/PricingCard.scss";
import checkmark from "../assets/static/icons/checkmark-sharp.svg";
import crown from "../assets/static/icons/crown.svg";

export default function PricingCard(props) {
  const {
    title,
    price,
    subtitle,
    features,
    btnBackgroundColor: backgroundColor,
    btnHoverColor: hoverColor,
    btnText: text
  } = props;

  const btnProps = { backgroundColor, hoverColor, text };

  const location = useLocation()
  const affId = new URLSearchParams(location.search).get('sj_aff')

  const handleClick = (affId) => {
    window.location.href = affId ? `https://cabin.salesjet.io/register?sj_aff=${affId}` : "https://cabin.salesjet.io/register";    
  };

  

  return (
    <div className="card">
      <img src={crown} className="card__crown" alt="best choice" />
      <h3 className="card__title">{title}</h3>
      <h4 className="card__subtitle">{subtitle}</h4>
      <div className="card__price-container">
        <span className="card__price">{price}</span>
      </div>
      <div className="card__features-container">
        <ul className="card__features-list">
          {features.map((feature, i) => (
            <div className="card__feature-container" key={i}>
              <li className="card__feature">
                <img
                  src={checkmark}
                  className="card__feature-checkmark"
                  alt="checkmark"
                />
                {feature}
              </li>
            </div>
          ))}
        </ul>
      </div>
      <Button
        {...btnProps}
        url="https://cabin.salesjet.io/register"
        clickHandler={() => handleClick(affId)}
      />
    </div>
  );
}
