import React, { useContext } from "react";
//import { sanitize } from "dompurify";

import VimeoPlayer from "./VimeoPlayer.jsx";

import "../assets/styles/components/Tools.scss";

import { LanguageContext } from "../content/textContent";
import { VideoContext } from "../content/videoContent";

export default function Tools(props) {
  const {
    tools: {
      title: { main1, blue, main2 },
      body: { paragraph1, paragraph2 },
    },
  } = useContext(LanguageContext);

  const { tools } = useContext(VideoContext);

  return (
    <section id="tools" className="tools">
      <div className="container">
        <div className="tools__left-container">
          <h1 className="tools__title">
            {main1} <span className="blue">{blue}</span> {main2}
          </h1>
          <VimeoPlayer
            videoId={tools}
            hideOnTabLand={false}
            hideOnLaptop={false}
            hideOnDesktop={true}
          />
          <p
            className="tools__text"
            dangerouslySetInnerHTML={{ __html: paragraph1 }}
          />
          <p
            className="tools__text"
            dangerouslySetInnerHTML={{ __html: paragraph2 }}
          />
          {/* <Button
            text={button}
            backgroundColor="#6066F2"
            hoverColor="#f0f0f0"
            width="180px"
          /> */}
        </div>

        <VimeoPlayer videoId={tools} hideOnTabLand={true} hideOnLaptop={true} />
      </div>
    </section>
  );
}
